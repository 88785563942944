.pagination-container{
  .pagination{
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      li{
        font-size: 1rem;
        padding: 0 1rem;
        background-color: #eee;
        margin: 0 2rem;
        cursor: pointer;
        &:hover{
          background: #96bcee80;
          color: #fff;
        }
      }
      .active{
        background-color: #96bcee;
        color: #fff;
      }
  }
}