.export-btn {
  border-radius: 5px;
  border: none;
  margin: 2px;
  padding: 8px;
  background-color: #5664d2;
  color: white;
}

.export-btn:disabled {
  background-color: rgb(149, 148, 148);
  cursor: not-allowed;
}

.date-btn {
  border-radius: 5px;
  border: none;
  margin: 2px;
  padding: 8px;
  background-color: #5664d2;
  color: white;
}

.react-date-picker__wrapper {
  border-radius: 5px;
  margin-right: 5px;
  color: hsl(0, 0%, 50%);
  padding: 5px;
}

.react-date-picker__calendar-button {
  background-color: lightgrey;
}

.course-col {
  margin-top: 1rem;
}

.users-col-inp {
  margin: 1rem 0 0 1rem;
}