.disabled-btn{
  width: 100%!important;
  padding: 10px 0!important;
  color: white!important;
  border: none!important;
  background-color: #ccc!important;
}

.disabled-btn:hover{
  background-color: #5664d2!important;
}